import type {
	GlobalWebsiteSettings,
	WebsitePage
} from '@SHARED/core/entities/WebsiteConfig';
import { PagesModule } from '@SHARED/core/entities/pages/module';

const defaultGlobalSettings: GlobalWebsiteSettings = {
	enableBreadcrumb: false,
	enableCondos: false,
	pilarPartnerLogo: null,
	enableDynamicPropertiesListingPageMetadata: false
};

export class WebsiteConfigModule {
	static getValidWebsitePagesConfig(pages: WebsitePage[]) {
		return pages.map(page => {
			// TODO: encontrar uma maneira de fazer isso sem usar if && criar um dicionário com o nome das páginas e suas funções
			if (page.name === 'WhoWeAre') {
				return {
					...page,
					brokers: PagesModule.getWhoWeArePageValidBrokers(page.brokers)
				};
			} else if (page.name === 'PropertyPage') {
				return {
					...page,
					variant: page.variant || 'default'
				};
			}

			return page;
		});
	}

	static DEFAULT_PILAR_PARTNER_LOGO_DESTINATION_URL =
		'https://www.soupilar.com.br';

	static getGlobalWebsiteSettings(
		globalSettings?: Partial<GlobalWebsiteSettings> | null
	): GlobalWebsiteSettings {
		return {
			...defaultGlobalSettings,
			...globalSettings
		};
	}
}
