<template>
	<div class="contact-item" target="_blank">
		<Component :is="icon" v-if="icon" class="contact-icon" />
		<div class="item-content">
			<p class="item-label default-font">
				{{ label }}
			</p>
			<Component
				:is="component"
				:to="url"
				target="_blank"
				class="item-value default-font"
				:class="{ 'footer-link': props.url }"
			>
				{{ content }}
			</Component>
		</div>
	</div>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components';

import type { FooterContactItem } from '@SHARED/presenters/PropertyPresenter';

defineOptions({ name: 'FooterContactItem' });

const props = defineProps<FooterContactItem>();

const component = computed<typeof NuxtLink | 'p'>(() =>
	props.url ? NuxtLink : 'p'
);
</script>

<style lang="scss" scoped>
.contact-icon {
	width: 2rem;
	height: 2rem;
}

.contact-item {
	display: flex;
	align-items: center;
	gap: 1rem;

	.item-content {
		display: flex;
		flex-direction: column;
		justify-content: center;

		.item-label {
			font-weight: 300;
		}

		.item-value {
			font-weight: 500;
			text-transform: uppercase;
			font-size: 0.875rem;
		}
	}
}
</style>
