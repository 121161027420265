<template>
	<aside class="sidebar" :class="animationClasses">
		<div
			v-if="showSidebar"
			v-on-click-outside="() => emit('close')"
			class="sidebar-content"
		>
			<CloseIcon class="close-icon" @click="emit('close')" />
			<ul class="link-wrapper">
				<HeaderLink
					v-for="(link, index) in filteredLinks"
					:key="link.url"
					:url="link.url"
					:text="link.text"
					:target="link.target"
					:text-color="textColorValue"
					:active="route.path === link.url"
					class="sidebar-link"
					:class="{ 'large-font': isFullPage }"
					:style="{ animationDelay: getDelay(index) }"
					@click-link="handleLinkClick(link)"
				/>
			</ul>

			<div v-if="socialMediaList" class="header-sidebar-social-media-links">
				<SocialIcon
					v-for="socialMedia in socialMediaList"
					:key="socialMedia.url"
					class="social-media-link"
					:social-media="socialMedia"
					target="_blank"
					:class="{ 'large-font': isFullPage }"
				/>
			</div>
		</div>
	</aside>
</template>

<script setup lang="ts">
import { onKeyStroke } from '@vueuse/core';
import { vOnClickOutside } from '@vueuse/components';

import type { SocialMedia } from '@SHARED/core/entities/SocialMedia';
import type {
	HeaderSectionLink,
	SocialMediaLink
} from '@SHARED/core/entities/sections/HeaderSection';
import type { CssSize, ResponsiveSize } from '@SHARED/utils/helperTypes';
import type { Color, WebsiteStyles } from '@SHARED/core/entities/WebsiteConfig';
import type { Animation } from '@SHARED/composables/useAnimation';

import { SocialMediaModule } from '@SHARED/core/entities/SocialMedia/module';
import { useAnimation } from '@SHARED/composables/useAnimation';
import { getCSSColorVar } from '@SHARED/utils/style';

import HeaderLink from '@SHARED/components/atoms/HeaderLink.vue';
import SocialIcon from '@SHARED/components/atoms/SocialIcon.vue';
import CloseIcon from '~icons/mdi/close';

defineOptions({ name: 'HeaderSidebar' });

const route = useRoute();

const styles = useState<WebsiteStyles>('styles');

type SidebarProps = {
	showSidebar: boolean;
	backgroundColor: string;
	textColor?: Color | null;
	isFullPage?: boolean;
	animationStyle?: Animation;
	links: HeaderSectionLink[];
	socialMediaLinks: SocialMediaLink[] | null;
	itemsPosition?: 'center' | 'left' | 'right';
	iconSize?: CssSize;
	fontSize?: ResponsiveSize | null;
};

const props = withDefaults(defineProps<SidebarProps>(), {
	isFullPage: false,
	animationStyle: 'slide-left',
	itemsPosition: 'center',
	iconSize: '2rem',
	fontSize: null,
	socialMediaLinks: null,
	textColor: null
});

type Emits = {
	(e: 'close'): void;
	(e: 'click-link', link: HeaderSectionLink, source: string): void;
};

const emit = defineEmits<Emits>();

onKeyStroke('Escape', () => emit('close'));

function handleLinkClick(link: HeaderSectionLink) {
	emit('close');
	emit('click-link', link, 'menu');
}

const { animationClasses, getAnimationClass } = useAnimation({
	animationStyle: props.animationStyle
});

const positionOptions = {
	center: 'center',
	left: 'flex-start',
	right: 'flex-end'
};

const sidebarWidth = computed<string>(() =>
	props.isFullPage ? '100%' : '400px'
);

function getDelay(index: number): string {
	return `${100 * (index + 1)}ms`;
}

const placement = computed<string>(() => positionOptions[props.itemsPosition]);

const filteredLinks = computed<HeaderSectionLink[]>(() =>
	props.links.filter(link => link && link.shouldShowOnSidebar !== false)
);

const socialMediaList = computed<SocialMedia[] | null>(() => {
	if (!props.socialMediaLinks) return null;

	const socialMediaObjects = props.socialMediaLinks.filter(
		link => link.shouldShowOnSidebar !== false
	);

	return SocialMediaModule.getValidAndUniqueSocialMediaList(socialMediaObjects);
});

const mobileFontSize = computed<CssSize>(
	() => props.fontSize?.mobile || '1.25rem'
);

const desktopFontSize = computed<CssSize>(
	() => props.fontSize?.desktop || '1.25rem'
);

const textColorValue = computed<string>(() =>
	getCSSColorVar(props.textColor || styles.value.appearance.text)
);

watch(
	() => props.showSidebar,
	shouldShowSidebar => {
		const animationStatus = shouldShowSidebar ? 'end' : 'start';

		animationClasses.value = getAnimationClass(animationStatus);
	}
);
</script>

<style lang="scss" scoped>
aside {
	background-color: v-bind(backgroundColor);
	color: v-bind(textColorValue);
}

.sidebar {
	position: fixed;
	height: 100vh;
	right: 0;
	z-index: 20;
	display: flex;
	width: 100%;
	transition: all 0.3s ease-in-out;

	@include screen-up(md) {
		width: v-bind(sidebarWidth);
	}

	.sidebar-content {
		display: flex;
		flex-direction: column;
		align-items: v-bind(placement);
		justify-content: center;
		width: 100%;
		height: 100%;
		max-height: 100dvh; // newer iOS devices
		position: relative;
		padding-left: 3rem;
		padding-right: 3rem;

		.sidebar-link {
			display: flex;
			justify-content: center;
			padding: 1rem;
			font-size: v-bind(mobileFontSize);

			@include screen-up(lg) {
				font-size: v-bind(desktopFontSize);
			}
		}
	}
}

.header-sidebar-social-media-links {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1.25rem;
	width: 100%;
	position: absolute;
	padding: 1.25rem 0;
	bottom: calc(0px + env(safe-area-inset-bottom)); // older iOS devices
	list-style: none;
	left: 0;

	.social-media-link {
		width: v-bind(iconSize);
		height: v-bind(iconSize);
		padding: 0;
		border: 0;

		&:hover {
			transform: scale(1.1);

			&::after {
				display: none;
			}
		}
	}
}

.close-icon {
	width: 2.5rem;
	height: 2.5rem;
	top: 2.5rem;
	right: 1.5rem;
	position: absolute;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	&:hover {
		transform: scale(1.1);
	}
}

.link-wrapper {
	display: flex;
	flex-direction: column;
	padding-left: 1rem;
	padding-right: 1rem;

	@include screen-up(md) {
		padding-left: 3rem;
		padding-right: 3rem;
	}
}
</style>
